<template>
  <header class="l-header">
    <div class="l-header__right">
      <h1 class="c-headerTitle__wrap">
        <a href="/" class="c-headerTitle">
          <Logo />
        </a>
      </h1>
    </div>
    <div class="l-header__left">
      <nav class="c-gnav__wrap">
        <Gnav />
      </nav>
    </div>
  </header>
</template>

<script>
import Gnav from "@/components/objects/Gnav.vue";
import Logo from "@/components/objects/Logo.vue";
export default {
  name: "Header",
  components: {
    Gnav,
    Logo,
  },
};
</script>

<style lang="scss">
/*=======
ヘッダー背景設定
=======*/
.l-header {
  font-size: 0;
  &__right {
    display: inline-block;
    width: 50%;
    vertical-align: bottom;
    font-size: $base-font-size;
    // margin-bottom: 1rem;
  }
  &__left {
    display: inline-block;
    width: 50%;
    vertical-align: baseline;
    font-size: $base-font-size;
    margin-bottom: 1rem;
    .c-gnav__wrap {
      height: 100%;
    }
  }
  @media screen and (max-width: 640px) {
    &__right {
      width: 100%;
    }
    &__left {
      width: 100%;
    }
  }
}

.c-headerTitle {
  display: block;
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: map-get($color_font, dark-gray);
  svg {
    display: inline-block;
    max-width: 240px;
    height: auto;
    line-height: 0;
  }
}
.b68ebd7a-0fca-4bb8-9e9a-d9b00504b31b {
  fill: #444;
  stroke: #444;
}
</style>
