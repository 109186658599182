<template>
  <ul class="c-gnav">
    <li
      v-for="(category, index) in categorys"
      v-bind:key="index"
      v-bind:class="'c-gnav__item ' + category.categoryClass"
    >
      <router-link v-bind:to="category.path" class="c-gnav__item__link">
        <font-awesome-icon :icon="category.icon" />
        <span>{{ category.name }}</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
import CategoryList from "@/data/categoryList";
export default {
  name: "Gnav",
  data() {
    return {};
  },
  computed: {
    categorys() {
      return CategoryList;
    },
  },
};
</script>

<style lang="scss">
.c-gnav {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
  &__item {
    width: $base-font-size * 3.6;
    height: $base-font-size * 3.6;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 50%;
    box-shadow: 1px 1px 1px rgba(#000000, 0.4) inset;
    &__link {
      width: 100%;
      height: 100%;
      padding: $base-padding-size * 1.5;
      text-decoration: none;
      text-align: center;
      // テキストを天地左右中央
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: stretch;
      align-content: center;
      // spanの表示位置
      position: relative;
      svg.svg-inline--fa {
        width: 100%;
        height: inherit;
      }
      span {
        position: absolute;
        top: $base-font-size * 2;
        right: 50%;
        padding: $base-padding-size;
        font-size: 1.2rem;
        background-color: map-get($color_font, light-gray);
        // transition
        transform-origin: top center;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        transform: scale(0, 0) translateX(50%) rotate(180deg);
        opacity: 0;
        &::first-letter {
          font-weight: bold;
        }
      }
      &:hover,
      &.is-hover {
        // hover action
        span {
          transform: scale(1, 1) translateX(50%) rotate(0deg);
          opacity: 1;
          border-radius: 3px;
          color: map-get($color_font, dark-gray);
          box-shadow: 1px 1px 1px rgba(#000000, 0.4);
        }
      }
    }
    @each $key, $color in $colors {
      @if $key == movie {
        &.is-#{$key} {
          background-color: $color;
          path {
            fill: map-get($color_font, black);
          }
        }
      } @else if $key == shiro {
        &.is-#{$key} {
          background-color: $color;
          border: $base-border-width solid;
          path {
            fill: map-get($color_font, black);
          }
        }
      } @else {
        &.is-#{$key} {
          background-color: $color;
          path {
            fill: map-get($color_font, white);
          }
        }
      }
    }
  }
}
</style>
