<template>
  <div class="home">
    <div class="p-home__logo">
      <Logo />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import db from '@/plugins/firestore'
// import storage from '@/plugins/cloudStorage'
import Logo from "@/components/objects/Logo.vue";
export default {
  name: "home",
  components: {
    Logo,
  },
  data() {
    return {};
  },
  created() {
    setTimeout(() => {
      this.$router.push("graphic");
    }, 5000);
  },
};
</script>

<style lang="scss">
.p-home__logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#fff, 0.95);
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #a9fdfb1a-ba59-4a53-b644-b6447e5a6f3c {
    transform: scale(1, 0);
    transform-origin: center center;
    animation: logoTransform 2s linear forwards;
    path {
      fill: rgba(#fff, 0);
      stroke-width: 0;
      animation: logoOpacity 3s linear forwards;
    }
  }
}
@keyframes logoTransform {
  0% {
    transform: scale(1, 0);
  }
  50% {
    transform: scale(1, 0.5);
  }
  95% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes logoOpacity {
  0% {
    fill: rgba(#fff, 0); /*透過*/
  }
  50% {
    fill: rgba(#333, 0.5); /*透過*/
  }
  100% {
    fill: rgba(#333, 1);
  }
}
</style>
