export default [
  {
    name: "Graphic",
    path: "/graphic",
    icon: ["fas", "paint-brush"],
    categoryClass: "is-graphic",
  },
  {
    name: "Code",
    path: "/code",
    icon: ["fas", "laptop-code"],
    categoryClass: "is-code",
  },
  {
    name: "Movie",
    path: "/movie",
    icon: ["fas", "file-video"],
    categoryClass: "is-movie",
  },
  {
    name: "About",
    path: "/about",
    icon: ["fas", "user"],
    categoryClass: "is-about",
  },
];
