<template>
  <footer class="l-footer">
    <p class="l-footer__copy">
      <small>2016-2019 &copy; s_sh All Rights Reserved</small>
    </p>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
.l-footer {
  padding: $base-padding-size;
  text-align: center;
}
</style>
