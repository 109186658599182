import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    itemRef: [],
    currentContentId: "",
  },
  mutations: {
    setItemRef(state, list) {
      state.itemRef = list;
    },
    setCurrentContentId(state, id) {
      state.currentContentId = id;
    },
  },
  actions: {},
  plugins: [createPersistedState()],
});
