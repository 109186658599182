<template>
  <div id="app" class="c-tenchi">
    <Header />
    <main class="l-main">
      <router-view />
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/layouts/Header.vue";
import Footer from "@/components/layouts/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
//------------------
// _base.scss
//------------------

// 1rem = 10pxになるように調整
html {
  font-size: 62.5%;
}

// iOS ボタンが反応しない対策
a,
button {
  cursor: pointer;
}

// 基本サイズ設定
body {
  width: 100%;
  min-height: 100vh;
  max-width: 960px;
  min-width: 320px;
  margin: 0 auto;
}
/*clearfix*/
.clearfix:after {
  visibility: hidden;
  height: 0;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
}

html {
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(251, 251, 251, 0.56) 55.67%,
    rgba(242, 242, 242, 1) 100%
  );
}

// ここから追加
.l-main {
  @media (max-width: $sp-size) {
    /* under 480px */
  }
  @media (min-width: $sp-size+1) and (max-width: $pc-size) {
    /* over 481px and under 768px */
  }
  @media (min-width: $pc-size+1) {
    /* over 769px */
    padding-right: $base-padding-size * 2;
    padding-left: $base-padding-size * 2;
  }
}

.c-tenchi {
  @mixin createTenchi($char) {
    content: $char;
    display: block;
    width: 30px;
    margin: 0 auto;
    padding: math.div($base-padding-size, 2);
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    border: 2px solid map-get($color_font, dark-gray);
    color: map-get($color_font, dark-gray);
  }
  padding: $base-padding-size * 2;
  &:before {
    @include createTenchi("天");
    margin-bottom: $base-padding-size;
  }
  &:after {
    @include createTenchi("地");
    margin-top: $base-padding-size;
  }
}

//----------------------
// 状態に付随した、スタイル
//----------------------

// モーダルが開いている場合、html・bodyがスクロールできないようにする
.is-modal-open {
  overflow: hidden;
}
</style>
